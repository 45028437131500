<template>
  <div>
    <div>
      <div v-if="form.sfty" class="text-info">采用告知承诺制</div>
      <div v-if="!form.sfty" class="d-flex align-items-center">
        <span class="text-danger"
          >不采用告知承诺制</span
        >
        <a @click="gznxClick" v-if="readOnly" href="javascript:;">{{
              form.gznxzm ? "（查看工作年限证明）" : ""
            }}</a>
        <el-upload
          v-if="!readOnly"
          class="upload-demo d-flex align-items-center ml-3"
          ref="gznxzm"
          action=""
          :auto-upload="false"
          :show-file-list="false"
          :accept="astrict.gznxzm.ext"
          :on-change="
            (file) => {
              return uploadFile(file, 'gznxzm');
            }
          "
        >
          <el-button size="mini" ref="gznxzm_up"
            ><i class="iconfont icon-tongyong_2_icontubiao_fill_upload mr-2"></i
            >上传文件</el-button
          >
          <div slot="tip" class="el-upload__tip ml-2">
            <a @click="gznxClick" href="javascript:;">{{
              form.gznxzm ? "（查看工作年限证明）" : ""
            }}</a>
            {{ astrict.gznxzm.text }}
          </div>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
import { checkFile, unitsChange } from "@/libs/common_user";
import { uploadFiles, getUploadLimit } from "@/api/common/public";

export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    bound: Array,
    form: Object,
    boundDict: {
      type: Array,
      default: function () {
        return ["value", "name"];
      },
    },
  },
  data() {
    return {
      astrict: {
        gznxzm: {},
      },
    };
  },
  methods: {
    gznxClick() {
      this.$yzImgDialog().show(this.getFileUrl(this.form.gznxzm));
      // if (this.form.gznxzm) {
      //   this.$yzImgDialog().show(this.getFileUrl(this.form.gznxzm));
      // } else {
      //   this.$refs.gznxzm_up.$el.click();
      // }
    },
    //获取文件限制
    getLimit() {
      getUploadLimit({ module: "WorkCertificate" }).then((res) => {
        if (res.status) {
          this.astrict.gznxzm["ext"] =
            "." + res.data.extLimit.replaceAll(",", ",.");
          this.astrict.gznxzm["size"] = res.data.sizeLimit;
          let size = unitsChange(res.data.sizeLimit);
          this.astrict.gznxzm["text"] =
            "只支持" + res.data.extLimit + "，类型的文件，且不超过" + size;
          this.$forceUpdate();
        }
      });
    },
    //上传文件
    uploadFile(file, key) {
      let _this = this;
      let fileRule = "";
      file = file.raw;
      let result = checkFile(file, this.astrict[key]);

      if (result.status) {
        if (key == "gznxzm") {
          fileRule = "WorkCertificate";
        }
        uploadFiles(fileRule, file, _this.form.ksbmbh).then((res) => {
          if (res.status) {
            let resFile = res.data[0];
            _this.$message({ type: "success", message: "上传成功" });
            _this.form[key] = resFile.fileId;
            this.$emit("input", this.form);
          }
        });
      } else {
        _this.$message({ type: "error", message: result.message });
      }
      this.$refs[key].clearFiles();
      return false;
    },
  },
  created() {
    this.getLimit();
  },
};
</script>

<style></style>
