<template>
  <div class="w-85 h-100 overflow d-flex justify-content-end">
      <div class="h-100 d-flex flex-column align-items-start justify-content-around">
        <div>(单位盖章)</div>
        <div>审查人签名:</div>
        <div>
          <span class="mr-5">年</span>
          <span class="mr-5">月</span>
          <span>日</span>
        </div>

    </div>
  </div>
</template>

<script>
import { getDictionary } from "@/libs/common_user";
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    bound: String,
    form: Object,
    boundDict: {
      type: Array,
      default: function () {
        return ["value", "name"];
      },
    },
  },

  data() {
    return {
    };
  },
  methods: {
    updateData() {
    },
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        this.updateData();
      },
    },
  },
  mounted() {
  },
  created() {
  },
};
</script>

<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
.listflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overflow {
  overflow-y: scroll;
}
.w-85{
  width: 85%;
}
</style>
